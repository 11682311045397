<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              Ads攻略のコツ
            </h3>
            <p class="text-body-1">
              <router-link to="/beginner">初めてのAds攻略</router-link>では、主にサイトやウェブページの改善すべき点などを紹介しました。<br />
              Ads攻略のコツではもう一歩踏み込んで、実際にアドセンスやアドパートナーを貼り付ける際に、または貼り付けてから気をつけるべきことなどを紹介していきます。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              ※ このページは不定期に更新を行っていくと思います。
            </p>
            <h4 class="text-h6 mb-4">
              広告を貼り付ける位置を考える
            </h4>
            <p class="text-body-1">
              基本的に広告は好きな位置に貼り付けることができますが、だからといって適当な位置に貼り付けるだけではその効果は発揮されないでしょう。<br />
              実は広告の威力が発揮される位置というのは存在します。<br />
              主に効果的とされているのが、ページ上部やコンテンツ直下、ページ下部に横長広告を、そしてサイドに縦長広告を貼り付ける方法です。<br />
              このほかにも効果的な貼り付け方は存在しますが、広告の種類（テキストかイメージか）や広告のサイズなどによって貼り付けるべき位置は変化します。<br />
              広告は訪問者に見てもらうべきものなので、ページが表示されてからスクロールをせず、かつ目立つ位置に貼り付けるのも効果的でしょう。
            </p>
            <h4 class="text-h6 mb-4">
              広告をページのコンテンツに同化させる
            </h4>
            <p class="text-body-1">
              確かに、広告は目立ったほうが訪問者に興味を持たれ、結果的にクリックされることにつながるかもしれません。<br />
              しかし、あまりにも広告がページの中で浮きすぎた存在になってしまうと、逆に訪問者に意識されすぎてしまい、結果的にクリック率を下げてしまうことになってしまいます。<br />
              広告のボーダーを消せる場合は消してみてもいいでしょうし、広告のリンクやテキスト、背景などをページのコンテンツに同化させるような広告の配色を選んでみるのもひとつの方法でしょう。
            </p>
            <h4 class="text-h6 mb-4">
              広告をさりげなく設置する
            </h4>
            <p class="text-body-1">
              広告は多ければ多いほどクリック率が上がることは確かです。<br />
              しかし、あまりにも多すぎると逆にクリックされなくなることが多くなるかもしれません。<br />
              広告をたくさん設置して広告表示数を上げることを努力するよりは、少ない広告数でより効率的にクリック率を上げる努力をするほうが、収益を上げる点では良いかもしれません。<br />
              その方法のひとつとして、ページのメインコンテンツの直下に関連情報としてさりげなく広告を貼り付ける方法があります。
            </p>
            <p class="text-body-1">
              ブログを例に挙げると、まずメインとなる記事が存在し、その下にトラックバックやコメント、コメント投稿フォームなどが位置しています。<br />
              この位置関係の中に広告を挿入したいなら、メインとなるコンテンツの直下に関連情報としてさりげなく広告を設置すべきでしょう。<br />
              そうすることによって、ブログの記事を読み終わった訪問者が次の情報を探しているときに、関連情報としての広告に目が移ることがあるかもしれません。<br />
              その広告がクリック報酬の場合、そこでクリックされればもちろんそれはあなたの収益になります。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'Arts',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "Arts"
      ]
    }),
  });
</script>
