<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              Ads ワード辞典
            </h3>
            <p class="text-body-1">
              Web広告関連の単語や誰かが作成した（と思われる）造語などの一覧です。<br />
              何かわからない単語があった場合などにご利用ください。
            </p>
            <h3 class="text-h6 mb-4">
              Web広告関連の単語
            </h3>
            <p class="text-body-1">
              Web広告関連の単語です。
            </p>
            <h4 class="text-h6 mb-4">
              アフィリエイト
            </h4>
            <p class="text-body-1">
              自分のサイトに広告を張り、サイト訪問者が広告クリック（タップ）したり契約したりすることによってその対価として広告主から広告料の一部を報酬として受け取るサービスのこと。<br />
              略せずにアフィリエイト・プログラムとも言う。
            </p>
            <h4 class="text-h6 mb-4">
              ASP
            </h4>
            <p class="text-body-1">
              アフィリエイト・サービス・プロバイダのこと。<br />
              アフィリエイトサービスを行っている会社等のことで、広告主とアフィリエイターをつなぐ架け橋のような存在でもある。
            </p>
            <h4 class="text-h6 mb-4">
              広告主
            </h4>
            <p class="text-body-1">
              マーチャントとも言う。<br />
              ASPに広告料を払って広告を出す個人や法人、または団体等のこと。
            </p>
            <h4 class="text-h6 mb-4">
              Google AdSense
            </h4>
            <p class="text-body-1">
              米<a href="https://www.google.com/" target="_blank">Google</a>社が提供している、コンテンツマッチ兼クリック報酬型のアフィリエイトサービスの名称。<br />
              読み方は「グーグル・アドセンス」、単に「アドセンス」とも言う。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              「Google AdSense」はGoogle社の商標です。
            </p>
            <h4 class="text-h6 mb-4">
              Google AdMob
            </h4>
            <p class="text-body-1">
              米<a href="https://www.google.com/" target="_blank">Google</a>社が提供している、モバイルアプリ向けコンテンツマッチ兼タップ報酬型のアフィリエイトサービスの名称。<br />
              読み方は「グーグル・アドモブ」、単に「アドモブ」とも言う。
            </p>
            <p class="text-body-1">
              Googleに買収される前のAdMobは世界有数のモバイル広告プラットフォームだった。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              「Google AdMob」はGoogle社の商標です。
            </p>
            <h4 class="text-h6 mb-4">
              Google AdWords
            </h4>
            <p class="text-body-1">
              米<a href="https://www.google.com/" target="_blank">Google</a>社が提供している、アドセンスに広告を出すための広告配信サービスの名称。<br />
              読み方は「グーグル・アドワーズ」、単に「アドワーズ」とも言う。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              「Google AdWords」はGoogle社の商標です。
            </p>
            <h4 class="text-h6 mb-4">
              アドセンサー
            </h4>
            <p class="text-body-1">
              アドセンスに参加しているアフィリエイターのこと。
            </p>
            <h4 class="text-h6 mb-4">
              アドモバー
            </h4>
            <p class="text-body-1">
              アドモブに参加しているアフィリエイターのこと。
            </p>
            <h4 class="text-h6 mb-4">
              アボセンス
            </h4>
            <p class="text-body-1">
              アドセンス利用者やアドモブ利用者がGoogle社からアカウント停止やアカウント剥奪されること。<br />
              理由はさまざまで、アドセンス利用者が原因（規約に反した）だったりGoogle社の手違い（？）だったりする。<br />
              単に「アボ」とも言う。<br />
              発祥はインターネット掲示板「２ちゃんねる（現：<a href="https://5ch.net/" target="_blank">５ちゃんねる</a>）」。
            </p>
            <h4 class="text-h6 mb-4">
              広告ユニット
            </h4>
            <p class="text-body-1">
              アドセンスやアドモブがそれぞれWebサイトやモバイルアプリに表示する広告のこと。<br />
              広告ユニットの種類や表示設定などは、各自のアドセンスまたはアドモブのアカウントの広告ページからいつでも作成や変更、管理が可能。
            </p>
            <h4 class="text-h6 mb-4">
              カラーパレット
            </h4>
            <p class="text-body-1">
              アドセンスの広告の配色を設定したもの。<br />
              各個人で自由にカスタマイズできるが、最近の広告ユニットには設定できないものも多い。
            </p>
            <h4 class="text-h6 mb-4">
              チャネル
            </h4>
            <p class="text-body-1">
              アドセンス広告やアドモブ広告の収益レポートをフィルタリングする際に設定できるもの。<br />
              主なものとして、「URLチャネル」「カスタムチャネル」がある。<br />
              チャネルを利用すると、各広告の表示数をトラッキングしたり広告別にレポートを作成したりできる。
            </p>
            <h4 class="text-h6 mb-4">
              レビューセンター
            </h4>
            <p class="text-body-1">
              広告レビューセンターのこと。<br />
              アドワーズ利用者からのプレースメント ターゲット広告（広告主がアドセンス利用者に直接狙って出せる広告）を審査し、自分のサイトに表示するかどうかを選ぶことができる。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'Dictionary',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "Dictionary"
      ]
    }),
  });
</script>
