<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              初めてのAds攻略
            </h3>
            <p class="text-body-1">
              お金に欲深い人で、かつアフィリエイト初心者の方なら、このサイトを訪れてまず最初にこのページをご覧になったのではないだろうかと思います。<br />
              まぁ、そうでなくてもまず始めに見るようなページだとは思いますが．．．
            </p>
            <p class="text-body-1">
              始めたばかりだと、実際に（というか具体的な行動としてまず）何をすればいいのかというのは案外よくわからなかったりするものです。<br />
              そこで、このページでは初めてアドセンスやヤフセンスをする人が具体的にすべきことなどを一通り紹介していきます。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              ※ このページは不定期に更新を行っていくと思います。
            </p>
            <h4 class="text-h6 mb-4">
              大手検索エンジンに登録する
            </h4>
            <p class="text-body-1">
              Yahoo! JAPANやGoogle、Bingなどといった大手の検索エンジンにサイトのクロールを依頼します。<br />
              アフィリエイトをする以前に、ウェブサイトの管理人として必ず行わなければならない常識的なことです。<br />
              これを怠ると、広告収入はもちろんですがサイトのアクセスアップすら望めないでしょう。
            </p>
            <p class="text-body-1">
              <a href="http://kouza.hamuinu.com/wiki_page/HTML%E8%AC%9B%E5%BA%A7/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%82%A2%E3%83%83%E3%83%97%E3%83%BBSEO%E7%B7%A8/%E7%AC%AC2%E7%AB%A0%20%E6%A4%9C%E7%B4%A2%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AB%E3%82%AF%E3%83%AD%E3%83%BC%E3%83%AB%E7%94%B3%E8%AB%8B%E3%81%97%E3%82%88%E3%81%86" target="_blank">こちらのページ</a>に、検索エンジンへの登録方法が詳しく記載されています。
            </p>
            <h4 class="text-h6 mb-4">
              大手SNSで情報発信する
            </h4>
            <p class="text-body-1">
              最近主流となっている方法で、フェイスブックやツイッター、ユーチューブなどの媒体を利用して、積極的にコンテンツを発信していきます。<br />
              Web検索よりもリアルタイムかつダイレクトに情報を届け、かつ発信する情報のコントロールも容易いのがメリットです。
            </p>
            <h4 class="text-h6 mb-4">
              サイトの更新を定期的に行う
            </h4>
            <p class="text-body-1">
              「ウェブサイトを製作して広告も貼り付け、サーバにアップロードしたし、これで大丈夫だろう。」
            </p>
            <p class="text-body-1">
              このような考え方は実はあまりよくありません。<br />
              情報を提供するウェブサイトというものは、日々常に新鮮でなければいけないからです。情報が更新されず、いつまでたっても同じ古い情報しかないようなサイトは、新規の訪問者を集めることはできてもリピーターと呼ばれる訪問者を増やすことはできません。<br />
              確かに新規の訪問者はリピーターに比べて広告をクリックする割合が高いのですが、新規は主に検索エンジンから訪れるため、いつ途絶えてしまうかわかりません。<br />
              それに比べてリピーターはお気に入りやブックマークから訪れることがほとんどな為、サイトの更新さえ怠っていなければリピーターを減らしてしまうということはまずありません。
            </p>
            <h4 class="text-h6 mb-4">
              サイトデザインやサイトアクセシビリティに気をつける
            </h4>
            <p class="text-body-1">
              いくらコンテンツが良かったとしても、デザインが良くないサイトやサイト内のアクセシビリティが非常に悪いサイトには、あまり人が訪れなくなるでしょう。<br />
              あなたがそのようなサイトを訪れたときのことをイメージしてください、あなたならどう感じるでしょうか？<br />
              おそらく、そのようなサイトには訪れたくなくなるでしょう。
            </p>
            <h4 class="text-h6 mb-4">
              サイトのSEO（検索エンジン最適化）を行う
            </h4>
            <p class="text-body-1">
              見た目的に良いだけでなくウェブページの文書構造自体も良いほうが、人間にとってもクロールロボットにとっても良いウェブページといえるでしょう。<br />
              そのために行うべきなのがSEO（検索エンジン最適化）です。<br />
              ページタイトルや概要、キーワード、本文中の見出しなどを見直したり、重要な単語がページに繰り返し登場しているかなどを見直したり、クロールロボットにスパム判定されるようなページになっていないかを確認したりなど、すべきことはページによって異なるかもしれませんが、最終的な目標は同じです。
            </p>
            <p class="text-body-1">
              <a href="https://kouza.hamuinu.com/wiki_category/accessup" target="_blank">こちらのページ</a>に、検索エンジンへの登録方法が詳しく記載されています。
            </p>
            <h4 class="text-h6 mb-4">
              他サイトと相互リンクを行う
            </h4>
            <p class="text-body-1">
              自分のサイトと同じジャンルやカテゴリの、他のサイトさんと相互リンクを行います。<br />
              これによってお互いのサイトの被リンク数（リンクされる数）が増えるので、お互いにとってメリットができ、アクセスアップにも繋がるでしょう。
            </p>
            <h3 class="text-h6 mb-4">
              もっとAds攻略
            </h3>
            <p class="text-body-1">
              「初めてのAds攻略」にて挙げた対策を一通り行ってしばらく時間がたつと、だんだん人（ビジター）がサイトに訪れるようになってきます。<br />
              これで、アクセスアップのための準備が整うので、あとはあなたしだいとなってきます。
            </p>
            <p class="text-body-1">
              アクセスアップ対策を行った後は、<router-link to="/arts">広告収入対策</router-link>を行うことで、サイトのさらなる広告収入アップに繋げることができます。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'Beginner',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "Beginner"
      ]
    }),
  });
</script>
