<template>
  <v-col cols="12" sm="5" md="4" lg="3" xl="2">
    <v-card outlined class="mb-4">
      <h4 class="sidebar text-subtitle-1 pt-1 pl-3 pr-3 pb-1">
        メニュー
      </h4>
      <v-list dense class="py-0">
        <v-list-item-group color="primary">
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title>ホーム</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-content>
              <v-list-item-title>このサイトについて</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/dictionary">
            <v-list-item-content>
              <v-list-item-title>Ads ワード辞典</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/beginner">
            <v-list-item-content>
              <v-list-item-title>初めてのAds攻略</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/arts">
            <v-list-item-content>
              <v-list-item-title>Ads攻略のコツ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card outlined class="mb-4">
      <h4 class="sidebar text-subtitle-1 pt-1 pl-3 pr-3 pb-1">
        スポンサード リンク
      </h4>
      <!-- Ads攻略 サイドバー -->
      <adsense
        class="text-subtitle-1 my-3 px-3"
        ad-client="ca-pub-7162784326630429"
        ad-slot="7603423238"
        ad-style="display: block"
        ad-format="auto"
        data-full-width-responsive="true">
      </adsense>
    </v-card>
  </v-col>
</template>

<script>
  export default {
    name: 'SideBar',
    props: [
      'items',
    ],
    data: () => ({
    }),
  }
</script>
