<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              Ads攻略 へようこそ！
            </h3>
            <p class="text-body-1 font-weight-bold">
              最近Web広告を始めたけれど、全然クリックされない．．．
            </p>
            <p class="text-body-1">
              ．．．というのは、Web広告を始めたばかりの方々が大抵感じていることなのではないでしょうか？
            </p>
            <p class="text-body-1">
              Web広告に関するページをインターネット検索で探しても、そのほとんどが表向きは効率よく稼ぐ方法を教えているが実は情報商材を高値で売りつけているサイトや、ページ一面広告だらけで、訪問者に「クリック（タップ）してください～」といわんばかりのサイトが大多数だったりします。<br />
              中には広告収入を増やすための、サイトのアクセスアップやSEO、クリック（タップ）数を増やすコツなどを真面目に載せているサイトもありますが、そんなサイトは少数派。
            </p>
            <p class="text-body-1">
              スマートフォンをはじめとしたモバイルデバイスがPCより優勢になってきたここ数年は、インターネットにアクセスできる端末が1人1台の時代に突入し、よりWeb広告、とりわけモバイル広告の重要性が高まってきています。
            </p>
            <p class="text-body-1">
              このサイトを見ているということは、おそらくあなたが、最近Web広告を始めたばかりの方か、もしくは初めて数ヶ月経つけれどいっこうに収入が増えない、という方なのだろうと思います。
            </p>
            <p class="text-body-1">
              当サイトでは、そんなWeb広告初心者の方でも．．．まぁ、初日からいきなり収入が増えるということはまず不可能ですが、初めて数ヶ月経ったあと．．．いや、数年経ったあとにある程度「結果」として返ってくるような、そんなサイトに成長させるためのちょっとした「コツ」を紹介していけたらなぁ．．．と思っています。
            </p>
            <p class="text-body-1">
              このサイトに訪れたWeb広告初心者の皆さんが、数年後に立派になっていることを願って．．．
            </p>
            <h3 class="text-h6 mb-4">
              更新やお知らせ
            </h3>
            <template v-for="(item, index) in news" :key="index">
              <template v-if="index < 3">
                <h4 class="text-subtitle-1 mb-4">
                  <v-icon>
                    mdi-calendar-month
                  </v-icon> {{ formattedDate( item.date ) }} <span v-if="isWithin1Month( item.date )" class="red--text font-weight-bold">New!</span>
                </h4>
                <p :key="item.text" v-html="decodeEntities( item.html )" class="text-body-1">
                </p>
              </template>
            </template>
            <h3 class="text-h6 mb-4">
              ナビゲーション
            </h3>
            <p class="text-body-1">
              関連単語の一覧は<router-link to="/dictionary">Ads ワード辞典</router-link>へどうぞ。
            </p>
            <p class="text-body-1">
              初心者の方は<router-link to="/beginner">初めてのAds攻略</router-link>へどうぞ。
            </p>
            <p class="text-body-1">
              使いこなすコツについては<router-link to="/arts">Ads攻略のコツ</router-link>へどうぞ。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';
  import { textConvertMixins } from '@/mixins/TextConvert';
  import moment from 'moment';

  import news from '@/assets/data/news.json'

  export default defineComponent({
    name: 'Home',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    mixins: [
      textConvertMixins
    ],
    data: () => ({
      breadCrumbs: [
        "Home"
      ],
      news: news.data
    }),
    methods: {
      formattedDate: function( date ){
        return moment( date ).format( 'YYYY/MM/DD' )
      },
      isWithin1Month: function( date ){
        return moment.duration( moment().diff( date ) ).asDays() < 30
      },
      combineNewsText: function( news ){
        return moment( news.date ).format( 'YYYY/MM/DD' ) + "<br />\n" + this.decodeEntities( news.html )
      }
    },
  });
</script>
