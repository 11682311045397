<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              このサイトについて
            </h3>
            <p class="text-body-1">
              このサイトでは、Web広告の初心者の方が、初めて数ヶ月から数年経ったあとにある程度「結果」として返ってくるような、そんなサイトに成長させるためのちょっとした「コツ」を紹介していけたらなぁ．．．と思っています。<br />
              まだまだ情報が少ないかもしれませんが、Web広告初心者の方にとって少しでも有益な情報を提供できるよう、努力していきたいと思っています。
            </p>
            <p class="text-body-1">
              このサイトに訪れたWeb広告初心者の皆さんが、数年後に立派な方になっていることを願って．．．
            </p>
            <h3 class="text-h6 mb-4">
              リンクについて
            </h3>
            <p class="text-body-1">
              このサイトはリンクフリーです。<br />
              自由にリンクしていただいてかまいませんが、できれば<router-link to="/">トップページ</router-link>へリンクをお願いしたいです。
            </p>
            <p class="text-body-1">
              リンク元は個人・法人等を問いませんが、公序良俗に反するサイトや出会い系サイト（<a href="https://ja-jp.facebook.com/" target="_blank">フェイスブック</a>や<a href="https://www.instagram.com/" target="_blank">インスタグラム</a>、<a href="https://twitter.com/" target="_blank">ツイッター</a>、<a href="https://www.tiktok.com/ja/" target="_blank">ティックトック</a>、<a href="https://mixi.jp/" target="_blank">ミクシィ</a>、<a href="https://www.pixiv.net/" target="_blank">ピクシブ</a>などの大手SNSは除く）、宗教系サイト、情報商材系サイトなどからのリンクはご遠慮ください。（上記のサイトにはいわゆる「ホームページ」の他に「ブログ」等も含みます。）
            </p>
            <p class="text-body-1">
              バナー等は作成していないので、リンクされる場合はテキストリンク等でお願いします。（これはバナーリンク等を拒否しているわけではありません。）
            </p>
            <h3 class="text-h6 mb-4">
              サイト内の広告に関して
            </h3>
            <p class="text-body-1">
              当サイト内に表示されている広告のうち、AdSense広告に関しては米Google社が広告表示のためにCookieやウェブビーコン等の技術を用いて情報を収集しています。<br />
              CookieやJavaScript等を無効にすることで広告を非表示にすることができますが、サイト内の一部サービスが利用できなくなる恐れがあります。
            </p>
            <p class="text-body-1">
              詳しくは、<a href="https://hamuinu.com/">はむいぬ.com</a>の<a href="https://hamuinu.com/privacy_policy">プライバシーポリシー</a>のページをご参照ください。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'About',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "About"
      ]
    }),
  });
</script>
